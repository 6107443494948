<template>
<v-app>
    <v-app-bar app>
        <v-btn icon @click="back">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
    </v-app-bar>
    <v-main style="padding: 0" v-if="loading">
        <v-container fluid>
            <v-row align="center" justify="center">
              <v-col align="center" justify="center">
                <loading-overlay :loading="loading" />
              </v-col>
            </v-row>
        </v-container>
    </v-main>
    <v-main style="padding: 0; margin-top: -60px" v-else>
        <v-container fluid fill-height>
            <md-dialog-alert :md-active.sync="isDisplayDialog" :md-content="dialog.dialogMessage" :md-title="dialog.dialogTitle" md-confirm-text="OK" @md-closed="backToMerchant" />
            <v-container v-if="isVerified">
                <div class="mb-10" align="center" justify="center">
                    <span class="mobile">{{details.mobile}}</span>
                </div>
                <div align="center" justify="center">
                    <span class="pin-label">Enter your Wallet PIN</span>
                </div>
                <div class="mb-10 mr-2 ml-2 mt-5 pin-label" justify="center" align="center">
                      <v-icon class="mr-2" color="red" size="16">mdi-information-outline</v-icon>
                      <div>Never share your Wallet PIN with anyone.</div>
                </div>
                <div align="center" justify="center">
                    <pincode-input v-model="code" placeholder="-" :secure="true" />
                </div>
                <div align="center" justify="center">
                    <div v-if="hasErrors" class="error-message mt-2">Please input 4-digit Wallet PIN</div>
                </div>
                <v-row align="center" justify="center">
                  <v-col align="center" justify="center">
                    <v-btn class="mr-4 mt-5" @click="submit" color="blue" dark>
                        Continue
                    </v-btn>
                  </v-col>
                </v-row>
            </v-container>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
import LoadingOverlay from '@/views/component/LoadingOverlay.vue';
import {
    mapGetters
} from 'vuex';
import API from '@/api/API'
import PincodeInput from 'vue-pincode-input';
import {
    isValidPinDetails,
    invalidParameterErrorCode,
    successCharge,
    errorCharge,
    isError500,
    shouldRedirectbackToMerchant
} from '@/utils/params.js';

export default {
    name: 'Pin',
    props: ['details', 'merchants'],
    components: {
        LoadingOverlay,
        PincodeInput
    },
    computed: {
        ...mapGetters([
            'customerData'
        ])
    },
    data: () => ({
        loading: true,
        isVerified: false,
        code: '',
        hasErrors: false,
        isDisplayDialog: false,
        dialog: {
            dialogMessage: '',
            dialogTitle: '',
            redirectUrl: ''
        }
    }),
    watch: {
        'code': {
            handler(value) {
                if (value && value.length === 4) {
                    this.hasErrors = false
                }
            },
        },
    },
    mounted() {
        this.verify()
    },
    methods: {
        back() {
            //remove data from storage?
            this.$router.back()
        },
        async verify() {
            this.code = ''
            this.loading = true
            this.isVerified = false
            if (isValidPinDetails(this.details)) {
                this.isVerified = true
                this.loading = false
            } else {
              const errorCode = invalidParameterErrorCode(this.details)
              console.log('401: Invalid parameters - ' + errorCode)
              this.$router.replace({path:'401', query: { errorCode: errorCode }})
            }
        },
        async submit() {
            this.hasErrors = false
            if (this.code && this.code.length === 4) {
                this.loading = true
                var params = this.details
                params.pin = this.code
                const verify = await API.charge(params)
                this.loading = false
                this.code = ""
                if (verify && verify.statusCode === '200') {
                    this.dialog = successCharge(this.merchants, this.customerData, verify.successUrl)
                } else {
                    this.dialog = errorCharge(verify, verify.failUrl)
                }
                this.isDisplayDialog = true
            } else {
                this.hasErrors = true
                this.code = ""
            }
        },
        backToMerchant() {
            if (!isError500(this.dialog.redirectUrl)) {
                if (shouldRedirectbackToMerchant(this.dialog.dialogMessage)) {
                    this.loading = true
                    var url = this.dialog.redirectUrl
                    var pattern = new RegExp(/\?.+=.*/g);
                    if (pattern.test(url)) {
                        url = `${url}&CRN=${this.customerData.CRN}`
                    } else {
                        url = `${url}?CRN=${this.customerData.CRN}`
                    }
                    window.location.replace(url)
                }
            } else {
                //this.$router.replace({path:'500', query: { errorCode: '10008' }})
            }
        }
    },

};
</script>

<style scoped>
.mobile {
    font-weight: bolder;
    font-size: 1.2em;
    color: rgb(10, 107, 101);
}

.pin-label {
    font-weight: bolder;
    font-size: 0.9em;
}

.error-message {
    font-weight: 400;
    font-size: 0.8em;
    color: red !important;
}

.bg-color {
    background-color: #4dccc6 !important;
    background-image: linear-gradient(315deg, #4dccc6 0%, #96e4df 74%) !important;
}
</style>
